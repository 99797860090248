import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import App from './App.vue';

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAMXfFq_zHQCvERp7a4OkBP35KWuxUeFTY', // Reemplaza con tu clave de API de Google Maps
    libraries: 'places', // Esto es opcional si necesitas más bibliotecas
  },
});

new Vue({
  render: (h) => h(App),
}).$mount('#app');