<template>
  <div id="app">
    <GoogleMapComponent />
  </div>
</template>

<script>
import GoogleMapComponent from "./components/GoogleMap.vue";

export default {
  name: "App",
  components: {
    GoogleMapComponent,
  },
};
</script>