<template>
  <div>
    <nav class="navbar">
      <div class="navbar-logo">
        <img src="@/assets/logo.jpg" alt="Logo" class="logo-img" />
      </div>
      <div class="navbar-title">
        GPS AMBULANCIAS LA GUARDIA
      </div>
    </nav>
    <div id="map"></div>
  </div>
</template>

<script>
import { db } from "../firebase"; 
import { ref, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database"; 

export default {
  name: "GoogleMapComponent",
  data() {
    return {
      map: null,
      markers: {}, // Almacenamos los marcadores por ID de dispositivo
      infoWindows: {}, // Almacenamos las ventanas de información por ID de dispositivo
    };
  },
  mounted() {
    this.loadGoogleMaps();
  },
  methods: {
    loadGoogleMaps() {
      if (typeof google === "undefined") {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAMXfFq_zHQCvERp7a4OkBP35KWuxUeFTY`;
        script.async = true;
        script.onload = this.initMap;
        document.head.appendChild(script);
      } else {
        this.initMap();
      }
    },
    initMap() {
      if (typeof google !== "undefined") {
        this.map = new google.maps.Map(document.getElementById("map"), {
          center: { lat: -17.81458, lng: -63.1561 },
          zoom: 12,
        });
        console.log("Mapa inicializado correctamente.");
        this.listenForDeviceLocations();
      } else {
        console.error("Google Maps no se ha cargado correctamente.");
      }
    },
    listenForDeviceLocations() {
      const dbRef = ref(db, 'devices');

      onChildAdded(dbRef, (snapshot) => {
        const data = snapshot.val();
        const deviceId = snapshot.key;
        // Inicialmente se agrega un marcador con una ubicación ficticia o un marcador vacío
        this.addPlaceholderMarker(deviceId, data.info);

        // Escuchar cambios en la ubicación de este dispositivo
        const locationsRef = ref(db, `devices/${deviceId}/locations`);
        onChildChanged(locationsRef, (locationSnapshot) => {
          const locations = locationSnapshot.val();
          const latestLocation = Object.values(locations).pop(); // Obtener la última ubicación

          if (latestLocation) {
            const position = {
              lat: latestLocation.latitude,
              lng: latestLocation.longitude,
            };
            this.updateMarker(deviceId, position, data.info);
          }
        });
      });

      onChildChanged(dbRef, (snapshot) => {
        const data = snapshot.val();
        const deviceId = snapshot.key;
        const locations = data.locations ? Object.values(data.locations).pop() : null;
        const info = data.info;

        if (locations) {
          const position = {
            lat: locations.latitude,
            lng: locations.longitude,
          };
          this.updateMarker(deviceId, position, info);
        } else {
          // Manejo de caso cuando no hay ubicaciones
          console.warn(`El dispositivo ${deviceId} aún no tiene ubicaciones.`);
        }
      });

      onChildRemoved(dbRef, (snapshot) => {
        const deviceId = snapshot.key;
        this.removeMarker(deviceId);
      });
    },
    addPlaceholderMarker(deviceId, info) {
      if (this.map && !this.markers[deviceId]) {
        const marker = new google.maps.Marker({
          position: { lat: 0, lng: 0 }, // Posición inicial de marcador vacío
          map: this.map,
          icon: {
            url: require('@/assets/ambulance.png'),
            scaledSize: new google.maps.Size(50, 50),
          },
        });

        const infoWindowContent = `
          <div>
            <h3>${info.name} ${info.surname}</h3>
            <p><strong>CI:</strong> ${info.ci}</p>
            <p><strong>Teléfono:</strong> ${info.phone}</p>
            <p><strong>Placa:</strong> ${info.plate}</p>
          </div>
        `;
        const infoWindow = new google.maps.InfoWindow({
          content: infoWindowContent,
        });

        marker.addListener("click", () => {
          Object.values(this.infoWindows).forEach((win) => win.close());
          infoWindow.open(this.map, marker);
        });

        this.markers[deviceId] = marker;
        this.infoWindows[deviceId] = infoWindow;

        console.log(`Marcador añadido con marcador de posición para el dispositivo ${deviceId}.`);
      }
    },
    addMarker(deviceId, position, info) {
      if (this.map && !this.markers[deviceId]) {
        const marker = new google.maps.Marker({
          position: position,
          map: this.map,
          icon: {
            url: require('@/assets/ambulance.png'),
            scaledSize: new google.maps.Size(50, 50),
          },
        });

        const infoWindowContent = `
          <div>
            <h3>${info.name} ${info.surname}</h3>
            <p><strong>CI:</strong> ${info.ci}</p>
            <p><strong>Teléfono:</strong> ${info.phone}</p>
            <p><strong>Placa:</strong> ${info.plate}</p>
          </div>
        `;
        const infoWindow = new google.maps.InfoWindow({
          content: infoWindowContent,
        });

        marker.addListener("click", () => {
          Object.values(this.infoWindows).forEach((win) => win.close());
          infoWindow.open(this.map, marker);
        });

        this.markers[deviceId] = marker;
        this.infoWindows[deviceId] = infoWindow;

        console.log(`Marcador añadido para el dispositivo ${deviceId} en la posición: `, position);
      }
    },
    updateMarker(deviceId, position, info) {
      if (this.map && this.markers[deviceId]) {
        this.markers[deviceId].setPosition(position);

        const infoWindowContent = `
          <div>
            <h3>${info.name} ${info.surname}</h3>
            <p><strong>CI:</strong> ${info.ci}</p>
            <p><strong>Teléfono:</strong> ${info.phone}</p>
            <p><strong>Placa:</strong> ${info.plate}</p>
          </div>
        `;
        this.infoWindows[deviceId].setContent(infoWindowContent);

        console.log(`Marcador actualizado para el dispositivo ${deviceId} a la nueva posición: `, position);
      }
    },
    removeMarker(deviceId) {
      if (this.markers[deviceId]) {
        this.markers[deviceId].setMap(null);
        delete this.markers[deviceId];

        if (this.infoWindows[deviceId]) {
          this.infoWindows[deviceId].close();
          delete this.infoWindows[deviceId];
        }

        console.log(`Marcador eliminado para el dispositivo ${deviceId}.`);
      }
    },
  },
};
</script>
<style scoped>
/* Estilos para el componente específico */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0fa1f6;
  color: #fff;
  padding: 10px 20px;
  font-family: 'Verdana', sans-serif; /* Aplica Verdana a la barra de navegación */
}

.navbar-logo img {
  height: 40px; /* Ajusta el tamaño del logo */
}

.navbar-title {
  flex-grow: 1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

#map {
  width: 100%;
  height: 600px;
}
</style>

<style>
/* Estilos globales */
body {
  font-family: 'Verdana', sans-serif; /* Aplica Verdana a todo el contenido de la página */
}
</style>